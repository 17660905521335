import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import type { SlotsResponse } from "~/types/api/Slots";
import type { Slot } from "~/types/Slot";

export const useSchedulerStore = defineStore("schedulerStore", () => {
  const from = new Date(Date.now());

  async function fetchSlots({
    service,
    user,
    locationId,
  }: {
    service: number;
    user?: number;
    locationId?: number;
  }): Promise<Ref<SlotsResponse> | null> {
    const fetchedSlots = await fetchSlotsFromDateAndMoreIfNoneFound({ from, locationId, service, user });

    if (fetchedSlots === null) {
      return null;
    }

    return ref(fetchedSlots.data);
  }

  async function getSlots({ service, user, locationId }: { service: number; user?: number; locationId?: number }) {
    if (import.meta.server) {
      return null;
    }

    return await fetchSlots({ locationId, service, user });
  }

  async function getNextSlotForHealthProfessional({
    service,
    user,
  }: {
    service: number;
    user: number;
  }): Promise<Slot | null> {
    if (import.meta.server) {
      return null;
    }

    const fetchedSlots = await fetchSlots({ service, user });

    if (fetchedSlots === null) {
      return null;
    }

    return fetchedSlots.value.slots[0];
  }

  return {
    getNextSlotForHealthProfessional,
    getSlots,
  };
});
